import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import { programsData } from "../../data/programsData";
import {
  GiBulldozer,
  GiCarWheel,
  GiPowerGenerator,
  GiForklift,
  GiTruck,
  GiFarmTractor,
  GiCrane,
  GiMineTruck,
} from "react-icons/gi";
import { MdFrontLoader } from "react-icons/md";
import { TbBackhoe } from "react-icons/tb";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const fadeImages = [
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%201.png?alt=media&token=34230e12-26db-4ab1-9233-401847042048",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%202.png?alt=media&token=25012b75-90c7-4741-9379-a049dafea642",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%203.png?alt=media&token=f70c1da9-2c90-4ecf-a6a5-883ce3d0bab1",
  },
];

const Home = () => {
  return (
    <div>
      <div className="Home">
        <div className="image-text-section">
          <div className="home-intro">
            <span>Welcome To Ausnihon Japanese Imports</span>
          </div>
          <div className="home-description">
            <p>
              Welcome to Ausnihon Japanese Imports, your premier destination for
              sourcing
              <br />
              top-quality machinery and equipment directly from Japan to
              Australia. <br />
              With a steadfast commitment to excellence and a deep understanding
              of the industry, <br />
              we bring you the very best in Japanese engineering and innovation.
            </p>
          </div>
        </div>
        <div className="intro">
          <div>What we are offering</div>
        </div>
        <div className="slide-container">
          <Fade>
            {fadeImages.map((fadeImage, index) => (
              <div key={index}>
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={fadeImage.url}
                />
              </div>
            ))}
          </Fade>
        </div>
        <div className="home-sections" id="sections">
          <div className="section-categories">
            {programsData.map((program) => (
              <div className="category">
                <span className="offering-heading">{program.heading}</span>
                <span className="offering-description">{program.details}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="home-categories">
          <div className="category-title">
            <span>Search By Categories</span>
          </div>
          <div className="stock-categories">
            <div className="category-items">
              <Link to="/stocks" className="link-category">
                <span>
                  <i>
                    <GiBulldozer />
                  </i>
                </span>
                <span>Dozer</span>
              </Link>
            </div>
            <div className="category-items">
              <Link to="/stocks" className="link-category">
                <span>
                  <i>
                    <TbBackhoe />
                  </i>
                </span>
                <span>Excavator</span>
              </Link>
            </div>
            <div className="category-items">
              <Link to="/stocks" className="link-category">
                <span>
                  <i>
                    <MdFrontLoader />
                  </i>
                </span>
                <span>Wheel Loader</span>
              </Link>
            </div>
            <div className="category-items">
              <Link to="/stocks" className="link-category">
                <span>
                  <i>
                    <GiForklift />
                  </i>
                </span>
                <span>Forklift</span>
              </Link>
            </div>
            <div className="category-items">
              <Link to="/stocks" className="link-category">
                <span>
                  <i>
                    <GiCrane />
                  </i>
                </span>
                <span>Crane</span>
              </Link>
            </div>
          </div>
          <div className="stock-categories">
            <div className="category-items">
              <Link to="/stocks" className="link-category">
                <span>
                  <i>
                    <GiTruck />
                  </i>
                </span>
                <span>Trucks</span>
              </Link>
            </div>
            <div className="category-items">
              <Link to="/stocks" className="link-category">
                <span>
                  <i>
                    <GiMineTruck />
                  </i>
                </span>
                <span>Road Construction</span>
              </Link>
            </div>
            <div className="category-items">
              <Link to="/stocks" className="link-category">
                <span>
                  <i>
                    <GiFarmTractor />
                  </i>
                </span>
                <span>Tractors</span>
              </Link>
            </div>
            <div className="category-items">
              <Link to="/stocks" className="link-category">
                <span>
                  <i>
                    <GiPowerGenerator />
                  </i>
                </span>
                <span>Generators</span>
              </Link>
            </div>
            <div className="category-items">
              <Link to="/stocks" className="link-category">
                <span>
                  <i>
                    <GiCarWheel />
                  </i>
                </span>
                <span>Other</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
