import React from "react";
import "./Contact.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import {
  BsFillTelephoneFill,
  BsEnvelopeFill,
  BsGlobeAsiaAustralia,
} from "react-icons/bs";

const Contact = () => {
  return (
    <div>
      <div className="Contact">
        <div className="image-text-section">
          <div className="home-intro">
            <span>Contact Us</span>
          </div>
        </div>
        <div className="contact-section">
          <div className="map-section">
            <div className="gmap-frame">
              <iframe
                title="location"
                className="iframe"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3143.5867575516236!2d145.10031107577203!3d-38.01009834528416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad66d72c7dbe053%3A0x6e582a1d0e9d59a3!2s11%2F13%20Boundary%20Rd%2C%20Mordialloc%20VIC%203195%2C%20Australia!5e0!3m2!1sen!2slk!4v1690769090867!5m2!1sen!2slk"
                width="600"
                height="450"
                style={{ border: "3" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="contact-details">
            <div className="contact-heading">Contact Details</div>
            <div className="contact-item">
              <i>
                <FaMapMarkerAlt />
              </i>
              <p>
                <b>Ausnihon Holdings Pty Ltd</b> <br />
                11-13, Boundary Rd 
                <br /> Mordialloc
                <br /> VIC 3195
                <br />
                Australia
              </p>
            </div>
            <div className="contact-item">
              <i>
                <BsFillTelephoneFill />
              </i>
              <p>+61 3 9088 0242</p>
            </div>
            <div className="contact-item">
              <i>
                <BsEnvelopeFill />
              </i>
              <p>info@ausnihon.com.au</p>
            </div>
            <div className="contact-item">
              <i>
                <BsGlobeAsiaAustralia />
              </i>
              <a href="http://www.ausnihon.com.au/">www.ausnihon.com.au</a>
            </div>
            <div className="contact-item">
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
