import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import Logo from "../../assets/logo-white.png";
import { FaBars, FaTimes } from "react-icons/fa";

const navLinks = [
  {
    path: "/home",
    display: "Home",
  },
  {
    path: "/stocks",
    display: "Stocks",
  },
  {
    path: "/about",
    display: "About",
  },

  {
    path: "/contact",
    display: "Contact",
  },
];

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div>
      <div className="header">
        <div className="logo-wrapper">
          <div className="lmct-no">LMCT 0012632</div>
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <ul className="header-menu">
          {/* <li><a href='../Home/Home'>Home</a></li>
            <li><a href='../Stocks/Stocks'>Stocks</a></li>
            <li><a href=''>About</a></li>
            <li><a href=''>Contact</a></li> */}
          {/* <li><a href='../Home/Home'>Admin</a></li> */}
          {navLinks.map((item, index) => (
            <NavLink to={item.path} className="nav-item" key={index}>
              {item.display}
            </NavLink>
          ))}
        </ul>
        <i className="menu-button" onClick={handleMenuToggle}>
          {mobileMenuOpen ? <FaTimes /> : <FaBars />}
        </i>
      </div>
      <div className={`mobile-menu ${mobileMenuOpen ? "open" : ""}`}>
        <ul className="mobile-items">
          {navLinks.map((item, index) => (
            <NavLink to={item.path} className="nav-item" key={index}>
              {item.display}
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Header;
