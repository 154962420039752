import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCuFH9dzQ-xeiMsg1vbPTw-Laza2iYkZGs",
    authDomain: "ausnihon-japanese-imports.firebaseapp.com",
    projectId: "ausnihon-japanese-imports",
    storageBucket: "ausnihon-japanese-imports.appspot.com",
    messagingSenderId: "1089951106777",
    appId: "1:1089951106777:web:e49c4ec42b49eb6a19a0c8",
    measurementId: "G-C73WNJBCKB"
  };

firebase.initializeApp(firebaseConfig);

export default firebase;