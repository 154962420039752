export const programsData = [
  {
    heading: "Used Machinery",
    details:
      "Japan has world-renowned construction machinery manufacturers and the world's largest auction of construction machinery to select exactly what you need.",
  },
  {
    heading: "Used Trucks",
    details:
      "Japan is one of the largest automobile manufacturing countries in the world. We will provide you with your desired truck",
  },
  {
    heading: "Agricultural Equipment",
    details:
      "We export tractors, land mowers, and cultivators etc, made in Japan to your destination.",
  },
  {
    heading: "Other",
    details:
      "We can provide the best quality generators, compressors and other machineries you are looking for.",
  },
];



