import React from "react";
import "./Footer.css";
import Logo from "../../assets/logo-white.png";
import { NavLink } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsFillTelephoneFill, BsEnvelopeFill } from "react-icons/bs";

const navLinks = [
  {
    path: "/home",
    display: "Home",
  },
  {
    path: "/stocks",
    display: "Stocks",
  },
  {
    path: "/about",
    display: "About",
  },

  {
    path: "/contact",
    display: "Contact",
  },
];

const Footer = () => {
  return (
    <div>
      <footer className="Footer-container">
        <div className="left-footer">
          <div>
            <img src={Logo} alt="" className="logo" />
          </div>
          <div className="footer-logo-text">
            <span>Ausnihon Holdings Pty Ltd</span>
          </div>
        </div>
        <div className="mid-footer">
          <ul className="footer-menu">
            {/* <li><a href='../Home/Home'>Home</a></li>
            <li><a href='../Stocks/Stocks'>Stocks</a></li>
            <li><a href='../About/About'>About</a></li>
            <li><a href='../Contact/Contact'>Contact</a></li> */}
            {navLinks.map((item, index) => (
              <NavLink to={item.path} className="nav-item" key={index}>
                {item.display}
              </NavLink>
            ))}
          </ul>
        </div>
        <div className="right-footer">
          <div>
            <i>
              <FaMapMarkerAlt />
            </i>
            <p>11-13, Boundary Rd, Mordialloc VIC 3195</p>
          </div>
          <div>
            <i>
              <BsFillTelephoneFill />
            </i>
            <p>+61 3 9088 0242</p>
          </div>
          <div>
            <i>
              <BsEnvelopeFill />
            </i>
            <p>info@ausnihon.com.au</p>
          </div>
        </div>
      </footer>
      <div className="copyright-footer">
        Copyright &copy; 2023 Ausnihon Holdings Pty Ltd. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
