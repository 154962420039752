import React from "react";
import "./About.css";
import Image1 from "../../assets/image1.png";
import Image2 from "../../assets/image2.png";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const fadeImages = [
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%201.png?alt=media&token=34230e12-26db-4ab1-9233-401847042048"
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%202.png?alt=media&token=25012b75-90c7-4741-9379-a049dafea642"
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%203.png?alt=media&token=f70c1da9-2c90-4ecf-a6a5-883ce3d0bab1"
  },
];

const About = () => {
  return (
    <div>
      <div className="About">
        <div className="image-text-section">
          <div className="home-intro">
            <span>About Us</span>
          </div>
        </div>
        <div className="intro">
          <div>Welcome to Ausnihon Japanese Imports Pty Ltd</div>
        </div>
        <div className="slide-container">
          <Fade>
            {fadeImages.map((fadeImage, index) => (
              <div key={index}>
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={fadeImage.url}
                />
              </div>
            ))}
          </Fade>
        </div>
        <div className="about-description">
          <p>
            We are a leading Australian business specializing in the importation
            of high-quality machinery and constructional and agricultural
            equipment from Japan. With a commitment to excellence and a strong
            focus on customer satisfaction, we strive to provide our clients
            with the finest products that meet their specific needs and
            requirements. At Ausnihon Japanese Imports, we understand the
            importance of reliable and efficient machinery in various
            industries, such as construction, agriculture, and beyond. That is
            why we have established strong partnerships with trusted suppliers
            in Japan, ensuring that we can offer you the best equipment
            available on the market.
            <br />
            <br />
            Our extensive product range includes a wide variety of machinery and
            equipment designed to enhance productivity, streamline operations,
            and drive success for your business. From excavators, bulldozers,
            and loaders to tractors, harvesters, and irrigation systems, we have
            a comprehensive selection that caters to diverse applications.
            <br />
            <br />
            We take pride in the exceptional quality of our imported products.
            Each item undergoes rigorous inspections and testing to ensure it
            meets international standards and surpasses your expectations. By
            sourcing our equipment directly from renowned Japanese
            manufacturers, we guarantee the highest level of performance,
            durability, and reliability. At Ausnihon Japanese Imports, we
            believe that success lies in building strong and lasting
            relationships with our valued customers. Our dedicated team of
            experts is here to assist you at every step of the process, from
            product selection and customization to logistics and after-sales
            support. We prioritize clear communication, prompt responses, and
            personalized solutions to ensure your complete satisfaction.
            <br />
            <br />
            In addition to our commitment to quality, we are also deeply
            committed to environmental sustainability. We actively seek out
            equipment that adheres to eco-friendly practices, helping you reduce
            your carbon footprint while maximizing efficiency and productivity.
            Whether you are a construction company looking for state-of-the-art
            machinery or an agricultural business in need of reliable equipment
            to optimize your operations, Ausnihon Japanese Imports is your
            trusted partner. We are passionate about helping you achieve your
            goals and are dedicated to delivering exceptional value and service.
            <br />
            <br />
            Thank you for considering Ausnihon Japanese Imports Pty Ltd as your
            preferred import partner. We look forward to serving you and
            contributing to your success. Please feel free to browse our website
            and contact us with any inquiries or requests.
          </p>
        </div>
        <div className="image-gallery">
          <div className="image">
            <img alt="img1" src={Image1} width="720" />
          </div>
          <div className="image">
            <img alt="img1" src={Image2} width="720" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
