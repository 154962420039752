import React, { useEffect, useState } from "react";
import "./StockItem.css";

import { useParams } from "react-router-dom";

import firebase from "../../config/firebaseConfig";

import emailjs from "@emailjs/browser";

const StockItem = () => {
  const { id } = useParams();
  const [stockData, setStockData] = useState(null);
  const [stockImages, setStockImages] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage the popup visibility
  const [emailAddress, setEmailAddress] = useState(""); // State to capture email address input
  const [telephone, setTelephone] = useState("");
  const [name, setName] = useState("");
  const [note, setNote] = useState(""); // State to capture note input
  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {
    const fetchStockItem = async () => {
      try {
        const docRef = firebase.firestore().collection("stocks").doc(id);
        const snapshot = await docRef.get();

        if (snapshot.exists) {
          setStockData(snapshot.data());
        } else {
          console.log("Item not found!");
        }
      } catch (error) {
        console.log("Error fetching item:", error);
      }
    };

    fetchStockItem();

    const fetchStockImages = async () => {
      try {
        const docRef = firebase.firestore().collection("stocksImages").doc(id);
        const snapshot = await docRef.get();

        if (snapshot.exists) {
          const { ModelID, ...restStockImages } = snapshot.data();
          setStockImages(restStockImages);
          setSelectedImage(restStockImages["url1"]);
        } else {
          console.log("Item not found!");
        }
      } catch (error) {
        console.log("Error fetching item:", error);
      }
    };
    fetchStockImages();
  }, [id]);

  const handleEmailButtonClick = () => {
    setIsPopupOpen(true); // Open the popup when the "Email" button is clicked
  };

  const handleSendButtonClick = (e) => {
    // Here you can handle the logic for sending the email or performing any actions with the input values
    console.log("Email Address:", emailAddress);
    console.log("Note:", note);

    var templateParams = {
      emailAddress: emailAddress,
      name: name,
      telephone: telephone,
      note: note,
    };

    emailjs
      .send(
        "service_sucgmch",
        "template_cddxhkn",
        templateParams,
        "zy20zPWuMkagLgfDO"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );

    // Close the popup after processing the form data
    setIsPopupOpen(false);
  };
  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleImageClick = (imageURL) => {
    setSelectedImage(imageURL);
  };

  if (!stockData && !stockImages) {
    return <p>Loading...</p>;
  }

  return (
    <div className="stock-item">
      <h2>Model</h2>
      <p>{stockData.Model}</p>
      <h2>Model ID</h2>
      <p>{id}</p>
      <div className="image-container">
        <img
          className="big-image"
          src={selectedImage}
          alt="Click to view Image"
        />
        <div className="grid-container" id="gridContainer">
          {stockImages &&
            Object.entries(stockImages).map(([key, value]) => (
              <div
                key={key}
                className="grid-item"
                onClick={() => handleImageClick(value)}
              >
                <img src={value} alt={key} loading="lazy" />
              </div>
            ))}
        </div>
      </div>
      <div className="details">
        <div className="details-content">
          <div>
            <h2>Year</h2>
            <p>{stockData.Year}</p>
            <h2>Mileage</h2>
            <p>{stockData.Travel}</p>
            <button className="card-btn" onClick={handleEmailButtonClick}>
              Email Us for Details
            </button>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <form onSubmit={handleSendButtonClick}>
              <h2 className="popup-heading">Please fill your details here.</h2>
              <div className="form-group">
                <label htmlFor="emailAddress">Your Email Address</label>
                <input
                  type="email"
                  id="emailAddress"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="emailAddress">Telephone Number</label>
                <input
                  type="tel"
                  id="tel"
                  value={telephone}
                  onChange={(e) => setTelephone(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="note">Description</label>
                <input
                  type="text"
                  id="note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
              <button type="submit" className="card-btn">
                Send
              </button>
              <button className="close-btn" onClick={handlePopupClose}>
                x
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default StockItem;
