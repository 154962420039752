import React, {useEffect, useState } from 'react'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import './Stocks.css'
import { Link } from 'react-router-dom';

import firebase from '../../config/firebaseConfig'

const options = [
  { value: 'all', label: 'All' },
  { value: 'Excavator', label: 'Excavator' },
  { value: 'Dozer', label: 'Dozer' },
  { value: 'Crane', label: 'Crane' },
  { value: 'Forklift', label: 'Forklift' },
  { value: 'Generator', label: 'Generator' },
  { value: 'Truck', label: 'Truck' },
  { value: 'Other', label: 'Other' }
]

const renderYearContent = (year) => {
  const tooltipText = `Tooltip for year: ${year}`;
  return <span title={tooltipText}>{year}</span>;
};
function Card(props) {
  return (
    <div className={props.className}>
    <div className="card">
      <div className="card-body">
        <div className="card-image">
          <img src={props.img} loading="lazy" />
        </div>
        <div>
          <h2 className="card-name">{props.name}</h2>
          <p className="card-year">ID : {props.modelID}</p>
          <p className="card-year">Year : {props.year}</p>
          <p className="card-hours">Milage : {props.hours}</p>
          <p className="card-price">Price : {props.price}</p>
          <Link to={`/stock-item/${props.modelID}`}>
          <button className="card-btn">Details</button>
          </Link>
          
        </div>
      </div>
    </div>
    </div>
  );
}

const Stocks = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [stockData, setStockData] = useState([]);

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        const snapshot = await firebase.firestore().collection('stocks').get();
        const data = snapshot.docs.map((doc) => doc.data());
        setStockData(data);
      } catch (error) {
        console.log('Error fetching stock data:', error);
      }
    };

    fetchStockData();
  }, []);


  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  return (
    <div className="stocks-container">
      <div className="left-pane">
        <div>
          <label className="lblAvailableStock">Available Stock (100)</label>
        </div>
        <div>
          <div>
            <h3 className="filter-header">Categories</h3>
          </div>
          <div>
            <ul className="category-list">
              <li className="category-item">
                <a href="#">
                  <span>All</span>
                  <span>###</span>
                </a>
              </li>
              <li className="category-item">
                <a href="#">
                  <span>Excavator</span>
                  <span>###</span>
                </a>
              </li>
              <li className="category-item">
                <a href="#">
                  <span>Dozer</span>
                  <span>###</span>
                </a>
              </li>
              <li className="category-item">
                <a href="#">
                  <span>Crane</span>
                  <span>###</span>
                </a>
              </li>
              <li className="category-item">
                <a href="#">
                  <span>Forklift</span>
                  <span>###</span>
                </a>
              </li>
              <li className="category-item">
                <a href="#">
                  <span>Generator</span>
                  <span>###</span>
                </a>
              </li>
              <li className="category-item">
                <a href="#">
                  <span>Truck</span>
                  <span>###</span>
                </a>
              </li>
              <li className="category-item">
                <a href="#">
                  <span>Other</span>
                  <span>###</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div>
            <h3 className="filter-header">Search</h3>
          </div>
          <div>
            <Select options={options} placeholder="Select Category" />
            
            <div className='year-picker-div'>
              <label className='lbl-model'>Model</label>
              <input className='search-input' placeholder="Model" type="text"></input>
            </div>
            
            <div className='year-picker-div'>
              <label className='lbl-model'>Year</label>
              <DatePicker
                className="datepicker"
                selected={fromDate}
                onChange={handleFromDateChange}
                showYearPicker
                dateFormat="yyyy"
                placeholderText="From"
                peekNextYear
                showYearDropdown
                dropdownMode="select"
              />
              <span> - </span>
              <DatePicker
                className="datepicker"
                selected={toDate}
                onChange={handleToDateChange}
                showYearPicker
                dateFormat="yyyy"
                placeholderText="To"
                peekNextYear
                showYearDropdown
                dropdownMode="select"
              />
            </div>

            <div className='year-picker-div'>
              <label className='lbl-model'>Hours</label>
              <input className="datepicker" type="text" placeholder="From"></input>
              <span> - </span>
              <input className="datepicker" type="text" placeholder="To"></input>
            </div>
            
          </div>
        </div>
      </div>
      <div className="right-pane">
        <label className='category-heading'>Used Machinery</label>
        <br/>
        <br/>
        {/* <div className="card-row">
          <Card
            img="https://first-truck.jp/product/resize.cgi/upload/1688525166_1.jpg?w=250"
            name="H29 UD Trucks Refrigerated Wing 33"
            year="2017"
            hours="20000"
            price="14,260 JPY"
          />
          <Card
            img="https://first-truck.jp/product/resize.cgi/upload/1688525166_1.jpg?w=250"
            name="H29 UD Trucks Refrigerated Wing 33"
            year="2017"
            hours="20000"
            price="14,260 JPY"
          />
          <Card
            img="https://first-truck.jp/product/resize.cgi/upload/1688525166_1.jpg?w=250"
            name="H29 UD Trucks Refrigerated Wing 33"
            year="2017"
            hours="20000"
            price="14,260 JPY"
          />
        </div>
        <div className="card-row">
          <Card
            img="https://first-truck.jp/product/resize.cgi/upload/1688525166_1.jpg?w=250"
            name="H29 UD Trucks Refrigerated Wing 33"
            year="2017"
            hours="20000"
            price="14,260 JPY"
          />
          <Card
            img="https://first-truck.jp/product/resize.cgi/upload/1688525166_1.jpg?w=250"
            name="H29 UD Trucks Refrigerated Wing 33"
            year="2017"
            hours="20000"
            price="14,260 JPY"
          />
          <Card
            img="https://first-truck.jp/product/resize.cgi/upload/1688525166_1.jpg?w=250"
            name="H29 UD Trucks Refrigerated Wing 33"
            year="2017"
            hours="20000"
            price="14,260 JPY"
          />
        </div> */}
        <div className="card-row">
        {stockData.map((stock, index) => (
            <Card
            key={stock.ModelID}
            modelID={stock.ModelID}
            img={stock.ImageURL}
            name={stock.Model}
            year={stock.Year}
            hours={stock.Travel}
            price={'Contact Us for Price'}
          />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Stocks