import './App.css';
import Home from './components/Home/Home'
import About from './components/About/About'
import Stocks from './components/Stocks/Stocks'
import Contact from './components/Contact/Contact'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import StockItem from './components/StockItem/StockItem';
import { Route, Routes, Navigate } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Header/>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path='/home' element={<Home/>} />
          <Route path='/stocks' element={<Stocks/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path="/stock-item/:id" element={<StockItem/>} />
        </Routes>
      <Footer/>
    </div>
  );
}

export default App;
